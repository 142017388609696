module.exports = [{
      plugin: require('../../../opt/build/repo/node_modules/@snowpact/hello-internet/node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../opt/build/repo/node_modules/@snowpact/hello-internet/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":2000,"quality":100,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"sizeByPixelDensity":false,"backgroundColor":"white","withWebp":false,"tracedSVG":false,"loading":"lazy","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../../../opt/build/repo/node_modules/@snowpact/hello-internet/node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://blog.mon-resto-halal.com"},
    },{
      plugin: require('../../../opt/build/repo/node_modules/@snowpact/hello-internet/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Hello Internet","short_name":"Actualité sur Hello Internet","start_url":"/","background_color":"#f7f0eb","theme_color":"#a2466c","display":"standalone","icon":"content/theme/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"7e896cb50b1aad07ec951f6622f22f5e"},
    }]
